import React from 'react';
import * as styles from './VCVideo.module.scss';
import { ComponentProps } from './VCVideo.model';

/**
 * The basic component used for images
 */
export const VCVideo = ({
  className,
  webmSrc,
  mp4Src,
  alt = 'An image',
}: ComponentProps) => {
  return (
    <div className={`${styles.mainWrapper} ${className}`}>
      <video playsInline controls>
        <source src={webmSrc} type="video/webm" />
        <source src={mp4Src} type="video/mp4" />
      </video>
      <figcaption aria-hidden>{alt}</figcaption>
    </div>
  );
};
