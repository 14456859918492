import React from 'react';
import { PortfolioPage } from '../../modules/PortfolioPage';
import { listItems } from '../../utils/data/projectList';
import { VCSectionBlock } from '../../globalComponents/VCSectionBlock';
import challengeImage from '../../images/projects/MIJO/challenge.jpg';
import workflowImage from '../../images/projects/MIJO/workflow.jpg';
import challengeImagew from '../../images/projects/MIJO/challenge.webp';
import workflowImagew from '../../images/projects/MIJO/workflow.webp';

// markup
const ProjectPage = () => {
  const projectData = listItems[1];
  const totalBlocks = 6;

  return (
    <main>
      <PortfolioPage metaData={projectData}>
        <VCSectionBlock
          title="The challenge"
          sectionNumber={1}
          sectionTotal={totalBlocks}
          image={{
            jpeg: challengeImage,
            webp: challengeImagew,
            alt: `AN EXAMPLE OF CANASTA ROSA'S UI.`,
          }}
        >
          <a
            href="https://www.forbes.com.mx/negocios-canasta-rosa-emprendedores-comercio-electronico-coronavirus/"
            target="_blank"
          >
            Canasta Rosa was an artisan e-commerce.
          </a>
          <br />
          <p>
            As it started to grow as a household name for e-commerce in Mexico,
            we to expand from only having a site to also having a mobile app,
            but, since the former one was developed by an external agency bit
            offered a completely different looks and experience to the users
            than the website. So we needed to unify the image and experience of
            a platform that would boost the reach and voice of small business
            during the pandemic.
          </p>
        </VCSectionBlock>
        <VCSectionBlock
          title="The basics"
          sectionNumber={2}
          sectionTotal={totalBlocks}
          iFrame={{
            src: 'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2F6CTB53fyCGUzvDTUy6gadU%2FMIJO-Master%3Fnode-id%3D3%253A197',
            alt: `THE FIGMA FILE CONTAINING THE BASIC ELEMENTS OF THE DESIGN SYSTEM.`,
          }}
        >
          <p>
            The first thing we need to do was unifying our first atoms,
            colors,text styles and icons.
          </p>
          <ul>
            <li>
              <b>For colors</b>, I took every color we were using in both
              platforms and grouped them in shades.
            </li>
            <li>
              <b>For text styles </b> started to create a hierarchy based on the
              text styles we had on production with some tweaks.{' '}
            </li>
            <li>
              <b>For icons</b>, we try to use a very rounded style to make the
              icons more friendly.
            </li>
          </ul>
        </VCSectionBlock>
        <VCSectionBlock
          title="The components"
          sectionNumber={3}
          sectionTotal={totalBlocks}
          iFrame={{
            src: 'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FHP4IPEcsEzBt9O6fVzXL3U%2FMIJO-Master%3Fnode-id%3D299%253A430%26t%3Dmu2MVc1qtw44w6o8-1',
            alt: `THE FIGMA FILE CONTAINING MIJO'S COMPONENTS.`,
          }}
        >
          <p>
            Once we have clear the basic building blocks of our UI, we needed to
            create new components from scratch.
            <br />I would often spent some time of my sprint doing so, from
            simple inputs like buttons, to complex ones like navigation bars,
            but as time passed and the System grew, other designers and devs
            started adding their own components to the system.
          </p>
        </VCSectionBlock>
        <VCSectionBlock
          title="Unifying our workflow"
          sectionNumber={4}
          sectionTotal={totalBlocks}
          image={{
            jpeg: workflowImage,
            webp: workflowImagew,
            alt: `An image showing the workflow of adding a component to the design system.`,
          }}
        >
          <p>
            Once the DS started to gain traction, I optimize the workflow of
            adding things to the design system vía Sketch plugins like{' '}
            <a href="https://github.com/airbnb/react-sketchapp" target="_blank">
              AirBnB's react-sketchapp{' '}
            </a>
            library for using the same coded component in Sketch, Figma, React
            and React native. And, once very component was unified, I deployed a
            Storybook site to work as our single source of truth.
          </p>
        </VCSectionBlock>
        <VCSectionBlock
          title="The end result"
          sectionNumber={5}
          sectionTotal={totalBlocks}
          iFrame={{
            src: 'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FHP4IPEcsEzBt9O6fVzXL3U%2FMIJO-Master%3Fnode-id%3D801%253A3616%26t%3Dmu2MVc1qtw44w6o8-1',
            alt: `Mijo's library`,
          }}
        >
          <p>
            With a unified workflow, every component that we created was being
            released on every platform at once. And we could create and redesign
            features super easy and fast, since Designers and Devs were, in a
            way, speaking the same language.
          </p>
        </VCSectionBlock>
      </PortfolioPage>
    </main>
  );
};

export default ProjectPage;
