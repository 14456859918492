import React from 'react';
import * as styles from './VCIFrame.module.scss';
import { ComponentProps } from './VCIFrame.model';

/**
 * The basic component for iFrames
 */
export const VCIFrame = ({
  className,
  src,
  alt = 'An iframe',
}: ComponentProps) => {
  return (
    <div className={`${styles.mainWrapper} ${className}`}>
      <iframe width="100%" height={'100%'} src={src} allowFullScreen />
      <figcaption>{alt}</figcaption>
    </div>
  );
};
