import React from 'react';
import * as styles from './VCSectionBlock.module.scss';
import { ComponentProps } from './VCSectionBlock.model';
import { VCImg } from '../VCImg';
import { VCIFrame } from '../VCIFrame';
import { VCVideo } from '../VCVideo';

/**
 * The section block for each case
 */
export const VCSectionBlock = ({
  className,
  children,
  image,
  video,
  iFrame,
  title,
  sectionNumber,
  sectionTotal,
}: ComponentProps) => {
  return (
    <section className={`${styles.mainWrapper} ${className}`}>
      <div>
        <p>
          <span>
            {sectionNumber} of {sectionTotal}
          </span>
        </p>
        <h3>{title}</h3>
        {children}
      </div>
      {image && (
        <VCImg
          jpegSrc={image.jpeg}
          webpSrc={image.webp}
          alt={image.alt}
          loading={sectionNumber > 2 ? 'lazy' : 'eager'}
        />
      )}
      {video && (
        <VCVideo webmSrc={video.webm} mp4Src={video.mp4} alt={video.alt} />
      )}
      {iFrame && <VCIFrame src={iFrame.src} alt={iFrame.alt} />}
    </section>
  );
};
