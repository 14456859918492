import React from 'react';
import { VCButton } from '../../globalComponents/VCButton';
import Helmet from 'react-helmet';
import * as styles from './PortfolioPage.module.scss';
import '../../utils/theme/globalTheme.scss';
//model
import { ComponentProps } from './Portfolio.model';
//Icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faGlasses,
  faEnvelopeSquare,
  faArrowRight,
  faArrowLeft,
  faPalette,
  faUser,
  faBriefcase,
} from '@fortawesome/free-solid-svg-icons';
import { VCNavBar } from '../../globalComponents/VCNavBar';
import { VCFooter } from '../../globalComponents/VCFooter';
library.add(
  fab,
  faGlasses,
  faEnvelopeSquare,
  faArrowRight,
  faArrowLeft,
  faPalette,
  faUser,
  faBriefcase
);
// markup
export const PortfolioPage = ({
  metaData = {
    projectTitle: 'Canasta Rosa + Google for startups',
    type: 'Project',
    description: 'A project',
    year: 2019,
    link: '/',
    figmaLink: '',
    gitLink: '',
  },
  children,
}: ComponentProps) => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <meta name="description" content={metaData.description}></meta>
        <title>{metaData.projectTitle}</title>
      </Helmet>
      <VCNavBar configuration="project" />
      <article className={styles.mainWrapper}>
        <header>
          <h1 className={styles.projectTitle}>{metaData.projectTitle}</h1>
          <p>{metaData.description}</p>
          <ul>
            {metaData.figmaLink && (
              <li>
                <VCButton
                  label="Go to the Figma file."
                  icon={['fab', 'figma']}
                  link={metaData.figmaLink}
                />
              </li>
            )}
            {metaData.gitLink && (
              <li>
                <VCButton
                  label="Go to the Git repository."
                  icon={['fab', 'github']}
                  link={metaData.gitLink}
                />
              </li>
            )}
          </ul>
        </header>
        {children}
        <VCFooter />
      </article>
    </>
  );
};
